import React from 'react'
import Layout from '../components/Layout'
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";

export default function products() {
    return (
        <Layout>

            <div className="products">

                <div className="hero">
                    <h1>Product Title</h1>
                </div>



            <div className="ssbody wrapper_max">
                <div className="navigation">

                    <h3>Our Products</h3>

                    <div className="list">
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                        <div>
                            <p>Termite Control</p>
                            <FaAngleRight/>
                        </div>
                      
                        
                    </div>

                </div>

                <div className="content">
                    <h2>Title</h2>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quas quos repellat omnis, voluptatibus nihil sunt! Eos illo sequi maxime saepe ducimus quidem cupiditate deleniti, distinctio voluptatum non beatae fuga ut.</p>
                </div>
            </div>

            </div>
        </Layout>
    )
}
